<template>
  <div class="container-modal-dialog">
    <b-modal v-model="showModal" :width="width" :close="closeModal">
      <div class="main-modal-dialog">
        <div class="has-background-white border-radius-20 px-4 py-3 flex-center-vertical border-modal-dialog">
          <div class="modal-warning-icon mr-3">
            <img :src="require('@/assets/img/warning-2.png')" alt="" />
          </div>
          <div>
            <p class="averta-black color-red label-18">{{ title }}</p>
            <p v-if="desc">{{ desc }}</p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalDialog',
  props: {
    width: {
      type: String,
      default: '455px'
    },
    open: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Waktu Survey Sudah Terisi '
    },
    desc: {
      type: String,
      default: 'Silakan pilih waktu lain untuk mengisi slot konsultan'
    }
  },
  data () {
    return {
      showModal: this.open
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    }
  },
  watch: {
    open (val) {
      this.showModal = val
    }
  }
}
</script>

<style scoped lang="scss">
.modal-warning-icon {
  height: 58px;

  img {
    height: 100%;
    object-fit: contain;
  }
}

.main-modal-dialog {
  height: 90vh;
}

.border-modal-dialog {
  position: relative;
  overflow: hidden;
}

.border-modal-dialog::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(to left, #B40007 50%, #ffffff 50%);
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: borderTimer 4s linear forwards;
}

@keyframes borderTimer {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
</style>
